import React from "react";
import { PrimaryButton } from "@fluentui/react";
export default () => (
  <div>
    <br />
    <div className="ms-Grid-col ms-sm12">
      <PrimaryButton text="Submit" type="submit" />
    </div>
  </div>
);
